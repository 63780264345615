function parseNumber(str, defaultNum) {
  const num = Number(str);
  if (Number.isNaN(num)) {
    return defaultNum;
  }
  return num;
}

function renderEllipsis() {
  const span = document.createElement('span');
  span.innerHTML = '&hellip;';
  span.classList.add('pagination-item');
  return span;
}

export class FiltersWithPagination {
  constructor({ container }) {
    this.container = container;

    this.items = [];
    this.itemsPerPage = parseNumber(container.dataset.itemsPerPage, 10);
    this.currentPage = 0;
    this.defaultCategory = container.dataset.defaultCategory || ' ';

    this.itemTemplate = container.querySelector('[data-item-template]').innerHTML;
    this.categoryFilter = container.querySelector('[data-role="category-filter"]');
    this.itemsWrapper = container.querySelector('[data-role="items-wrapper"]');
    this.paginationWrapper = container.querySelector('[data-role="pagination-wrapper"]');

    this.setItems = this.setItems.bind(this);
    this.renderItem = this.renderItem.bind(this);

    this.init();
  }

  init() {
    const { src } = this.container.dataset;
    fetch(src)
      .then(res => res.json())
      .then(this.setItems);

    this.categoryFilter.addEventListener('change', e => this.setCategory(e.target.value));
  }

  setItems(items) {
    this.items = items;
    this.generateCategoriesFromItems();
    this.goToPage(0);
  }

  setCategory(category) {
    this.category = category;
    this.goToPage(0);
  }

  generateCategoriesFromItems() {
    const categories = [];
    for (const item of this.items) {
      for (const category of item.categories) {
        if (categories.indexOf(category) === -1) {
          categories.push(category);
        }
      }
    }
    categories.sort().reverse();

    this.categoryFilter.innerHTML = `<option value="">${this.defaultCategory}</option>${categories.map(c => `<option>${c}</option>`).join('')}`;
  }

  renderPagination() {
    const totalPages = Math.ceil(this.getFilteredItems().length / this.itemsPerPage);
    const maxDiff = 2;
    let i = 0;
    let ellipsisBefore = false;
    let ellipsisAfter = false;

    this.paginationWrapper.innerHTML = '';

    if (totalPages < 2) return;

    while (i < totalPages) {
      const diff = i - this.currentPage;
      const isFirst = i === 0;
      const isLast = i === totalPages - 1;
      const inRangeAfter = diff <= maxDiff;
      const inRangeBefore = diff >= -maxDiff;
      const inRange = inRangeAfter && inRangeBefore;
      if (!inRangeAfter && !ellipsisAfter) {
        ellipsisAfter = true;
        this.paginationWrapper.appendChild(renderEllipsis());
      } else if (!inRangeBefore && !ellipsisBefore) {
        ellipsisBefore = true;
        this.paginationWrapper.appendChild(renderEllipsis());
      } else if (inRange || isFirst || isLast) {
        const button = document.createElement('button');
        button.innerHTML = i + 1;
        button.classList.add('pagination-item');
        button.addEventListener('click', this.goToPage.bind(this, i));
        button.classList.toggle('pagination-item--active', i === this.currentPage);
        this.paginationWrapper.appendChild(button);
      }
      i += 1;
    }
  }

  renderItems() {
    this.itemsWrapper.innerHTML = this.getFilteredItems()
      .slice(this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage)
      .map(this.renderItem)
      .join('');
  }

  renderItem(item) {
    return this.itemTemplate
      .replace('{{href}}', item.href)
      .replace('{{title}}', item.title)
      .replace('{{date}}', item.date);
  }


  getFilteredItems() {
    return this.items
      .filter(item => !this.category || item.categories.includes(this.category));
  }

  goToPage(i) {
    this.currentPage = i;
    this.renderItems();
    this.renderPagination();
  }
}
