// node-modules
import * as Swiper from 'swiper/dist/js/swiper';

// assets
import 'swiper/dist/css/swiper.min.css';
import './swiperSlider.scss';

const sliderOptions = {
  spaceBetween: 0,
  speed: 1200,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  keyboard: true,
};

export function servicesSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 2.7,
    effect: 'slide',
    spaceBetween: 32,
    preventClicksPropagation: true,
    pagination: {
      el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
      type: 'progressbar',
    },
    breakpoints: {
      1023: {
        slidesPerView: 2.2,
      },
      800: {
        slidesPerView: 2.2,
      },
      640: {
        slidesPerView: 1.2,
      },
    },
  });
}

export function coverSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 1,
    effect: 'slide',
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      1023: {
        spaceBetween: 0,
      },
    },
  });
}

export function companySlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 1,
    effect: 'fade',
    spaceBetween: 0,
    // autoplay: {
    //   delay: 4000,
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      1023: {
        spaceBetween: 0,
      },
    },
  });
}

export function imagesSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 1,
    effect: 'fade',
    spaceBetween: 0,
    breakpoints: {
      1023: {
        spaceBetween: 0,
      },
    },
  });
}

export function contentSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 1,
    effect: 'slide',
    spaceBetween: 0,
    // parallax: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    controller: {
      control: imagesSlider(document.querySelector('[data-role="slider-images"]')),
      by: 'slide',
    },
    breakpoints: {
      1023: {
        spaceBetween: 0,
      },
    },
  });
}
