// node-modules
import ScrollMagic from 'scrollmagic';


// constants
import { SELECTORS } from '../../scripts/constants';

// other
import { controller } from '../../scripts/utils/scrollmagic';

// assets
import './header.scss';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('header');
  if (!header) {
    return null;
  }

  return new ScrollMagic.Scene({
    triggerElement: document.body,
    triggerHook: 'onLeave',
    offset: 30,
  })
    .setClassToggle(header, SELECTORS.active)
    .addTo(controller);
});
