// node-modules
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd.min';
import _debounce from 'lodash/debounce';
import PubSub from 'pubsub-js';

// constants
import { LAZY_LOAD_EVENTS } from '@/components/lazy-load';

const defaults = {
  el: null,
  item: '[data-role="masonry-item"]',
};
export class MasonryGrid {
  constructor(props) {
    const options = Object.assign({}, defaults, props);
    this.el = options.el;
    this.item = options.item;
    this.grid = null;

    this.updateGrid = this.updateGrid.bind(this);
    this.updateGrid_debounced = _debounce(this.updateGrid, 100);
    this.init();
  }


  initGrid() {
    this.grid = new Isotope(this.el, {
      transitionDuration: 0,
      itemSelector: this.item,
      percentPosition: true,
    });
    imagesLoaded(this.el).on('progress', () => {
      this.grid.layout();
    });
  }

  updateGrid() {
    this.grid.layout();
  }


  destroy() {
    window.removeEventListener('resize', this.updateGrid_debounced);
  }

  init() {
    this.initGrid();
    window.addEventListener('resize', this.updateGrid_debounced);
    PubSub.subscribe(LAZY_LOAD_EVENTS.imageLoaded, () => {
      this.grid.layout();
    });
    PubSub.subscribe('updateGrid', () => {
      this.grid.layout();
    });
  }
}
