// node-modules
import 'letteringjs';
import ScrollMagic from 'scrollmagic';
import PubSub from 'pubsub-js';

// constants
import { SELECTORS } from '@/scripts/constants';
import { PRELOADER_EVENTS } from '@/components/preloader';

// assets
import './animations.scss';

// other
import { controller } from '@/scripts/utils/scrollmagic';

const run = (wrapper) => {

  $(wrapper).find('[data-split-text="lines"]')
    .lettering('lines')
    .children('span')
    .text((_, text) => text.trim())
    .lettering('words')
    .children('span')
    .lettering()
    .children('span');

  $(wrapper).find('[data-split-text="lines-only"]')
    .lettering('lines')
    .children('span')
    .text((_, text) => text.trim())
    .lettering('lines')
    .children('span')
    .removeClass()
    .addClass('inner-line');


  const covers = wrapper.querySelectorAll('[data-animation-cover]');
  if (covers.length) {
    covers.forEach((cover) => {
      PubSub.subscribeOnce(PRELOADER_EVENTS.finishLoading, () => {
        cover.querySelectorAll('[data-animation]')
          .forEach(el => el.classList.add(SELECTORS.loaded));
      });
    });
  }

  wrapper.querySelectorAll('[data-animation="stagger-list"]')
    .forEach((container) => {
      const delay = getComputedStyle(container).getPropertyValue('--delay');
      container.querySelectorAll('[data-animation="stagger-item"]')
        .forEach((item, i) => {
          item.style.setProperty('--delay', `${parseFloat(delay) + i / 10}s`);
        });
    });

  wrapper.querySelectorAll('[class^="line"]')
    .forEach((container) => {
      const delay = getComputedStyle(container).getPropertyValue('--delay');
      container.querySelectorAll('[class^="char"]')
        .forEach((item, i) => {
          item.style.setProperty('--delay', `${parseFloat(delay) + i / 30}s`);
          item.style.setProperty('--duration', `${0.75 + i / 20}s`);
        });
    });

  wrapper.querySelectorAll('[data-animation="lines"]')
    .forEach((container) => {
      const delay = getComputedStyle(container).getPropertyValue('--delay');
      const staggerDelay = getComputedStyle(container).getPropertyValue('--stagger-delay');
      container.querySelectorAll('.inner-line')
        .forEach((item, i) => {
          item.style.setProperty('--delay', `${parseFloat(delay) + (staggerDelay ? i * parseFloat(staggerDelay) * 0.66 : i / 30)}s`);
          item.style.setProperty('--duration', `${0.75 + (staggerDelay ? i * parseFloat(staggerDelay) : i / 20)}s`);
        });
    });

  wrapper.querySelectorAll('[data-animation-container]')
    .forEach((container) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: container,
        triggerHook: 'onEnter',
        offset: +container.dataset.offset || 200,
      })
        .on('enter', () => {
          container.querySelectorAll('[data-animation]')
            .forEach(element => element.classList.add(SELECTORS.loaded));
        })
        // .addIndicators()
        .addTo(controller);
    });
};

run(document);
