import { Loader } from 'google-maps';
import MAP_STYLES from './styles.json';
import isIe from '@/scripts/utils/detect-ie'; // eslint-disable-line

export function initGoogleMaps(el) {
  const {
    key, options, coords, markerImage,
  } = el.dataset;
  const { lat, lng } = JSON.parse(coords);

  let Google; // eslint-disable-line
  const loader = new Loader(key, { version: 'weekly' });

  loader.load().then((google) => {
    const center = new google.maps.LatLng({ lat, lng });

    const map = new google.maps.Map(el, {
      center: { lat, lng },
      styles: MAP_STYLES,
      ...JSON.parse(options),
    });
    const marker = new google.maps.Marker({
      position: center,
      icon: isIe() ? null : markerImage,
      optimized: false,
      origin: isIe() ? null : new google.maps.Point(16, 16),
    });
    marker.setMap(map);

    Google = google;
  });
}
