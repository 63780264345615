// node-modules
import { TweenLite } from 'gsap/TweenLite';

(function scrollToTop() {
  const scrollButton = document.querySelector('[data-scroll-top]');

  if (scrollButton) {
    scrollButton.addEventListener('click', () => {
      TweenLite.to(window, 0.8, { scrollTo: { y: 0, autoKill: false } });
    });
  }
}());
