// node-modules
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';
import { TweenLite, Power1 } from 'gsap/TweenMax';
import { ScrollToPlugin } from 'gsap/all';

// polyfills
import 'dom4';

// default components
// import '@/components/barba';
import '@/components/lazy-load';
import '@/components/preloader';
import '@/components/footer';
import '@/components/scrollToTop';
import '@/components/header';
import '@/components/animations';
import '@/components/buttons';
import '@/components/scrollMagic';

// functional components
import { scrollDownInit } from '@/components/scrollDown';
import {
  servicesSlider,
  coverSlider,
  companySlider,
  contentSlider,
} from '@/components/swiperSlider';
import { Form } from '@/components/Form';
import { MasonryGrid } from '@/components/MasonryGrid';
import { Tabs } from '@/components/tabs';
import { menuToggle } from '@/components/hamburgerMenu';
import { initGoogleMaps } from './components/googleMaps';
import { Showrooms } from '@/components/Showrooms';
import { plusCursor } from '@/components/plusCursor';
import { FiltersWithPagination } from './components/FiltersWithPagination';

// assets
import 'styles/main.scss';
import 'fonts';


// eslint-disable-next-line
const plugins = [ScrollToPlugin];

TweenLite.defaultEase = Power1.easeOut;


document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
  svg4everybody();
  // init scroll-down button
  scrollDownInit();
  plusCursor();

  // init menu
  menuToggle();

  function initShowroomSearch() {
    const showroomContainer = document.querySelector('[data-type="showrooms"]');
    if (!showroomContainer) return null;
    const showroomGrid = showroomContainer.querySelector('[data-type="showroom-grid"]');
    return new Showrooms({
      showroomContainer,
      showroomGrid,
    });
  }
  initShowroomSearch();

  // init map
  document.querySelectorAll('[data-role="google-maps-container"]')
    .forEach(el => initGoogleMaps(el));

  // init sliders
  document.querySelectorAll('[data-role="slider-services"]')
    .forEach(container => servicesSlider(container));

  document.querySelectorAll('[data-role="slider-cover"]')
    .forEach(container => coverSlider(container));

  document.querySelectorAll('[data-role="slider-company"]')
    .forEach(container => companySlider(container));
  //
  // document.querySelectorAll('[data-role="slider-images"]')
  //   .forEach(container => imagesSlider(container));

  document.querySelectorAll('[data-role="slider-content"]')
    .forEach(container => contentSlider(container));

  // init form
  document.querySelectorAll('[data-role="contact-form"]')
    .forEach(element => new Form(element));

  // init tabs
  document.querySelectorAll('[data-role="tab-list-container"]')
    .forEach(el => new Tabs({
      mainContainer: el,
    }));

  // init masonry grid
  function initMasonry() {
    document.querySelectorAll('[data-role="masonry-grid"]')
      .forEach(el => new MasonryGrid({ el }));
  }
  initMasonry();

  // init filters
  document.querySelectorAll('[data-role="filters-with-pagination"]')
    .forEach(container => new FiltersWithPagination({ container }));
});
