export const FORM_ELEMENTS = {
  input_block: '[data-role="input-block"]',
  input_block__input: '[data-role="input"]',
  input_block__label: '[data-role="label"]',
  input_block__error: '[data-role="error"]',
  captcha_block: '[data-role="captcha-block"]',
  content_block: '[data-role="content-block"]',
  message_block: '[data-role="message-block"]',
  message_loader: '[data-role="message-loader"]',
  message_success__mark: '[data-role="success-mark"]',
  message_error__mark: '[data-role="error-mark"]',
};

export const FORM_SELECTORS = {
  focused: 'is-focused',
  invalid: 'is-invalid',
  required: 'is-required',
};
