// node-modules
import { Linear } from 'gsap/all';
import ScrollMagic from 'scrollmagic';
import { controller } from '../../scripts/utils/scrollmagic';
import 'animation.gsap';
import 'debug.addIndicators';


(function initParallax() {
  document.querySelectorAll('[data-parallax]').forEach((el) => {
    if ($(window).width() < 640) {
      new ScrollMagic.Scene({
        triggerElement: el,
        duration: '100%',
        triggerHook: 'onEnter',
        offset: +el.dataset.offset || 100,
      })
        .setTween(el, { x: '-40', ease: Linear.easeNone })
        .addTo(controller);
    } else {
      new ScrollMagic.Scene({
        triggerElement: el,
        duration: '100%',
        triggerHook: 'onEnter',
        offset: +el.dataset.offset || 100,
      })
        .setTween(el, {x: '-170', ease: Linear.easeNone})
        .addTo(controller);
    }
  });
  document.querySelectorAll('[data-parallax-right]').forEach((el) => {
    if ($(window).width() < 640) {
      new ScrollMagic.Scene({
        triggerElement: el,
        duration: '100%',
        triggerHook: 'onEnter',
        offset: +el.dataset.offset || 100,
      })
        .setTween(el, { x: '40', ease: Linear.easeNone })
        .addTo(controller);
    } else {
      new ScrollMagic.Scene({
        triggerElement: el,
        duration: '100%',
        triggerHook: 'onEnter',
        offset: +el.dataset.offset || 100,
      })
        .setTween(el, {x: '170', ease: Linear.easeNone})
        .addTo(controller);
    }
  });
}());
