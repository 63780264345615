import { TweenMax } from 'gsap/TweenMax';
// import { SELECTORS } from '../../scripts/constants';

import './plusCursor.scss';

export function plusCursor() {
  // const $plusCursor = $('[data-plus-cursor]');
  // const $container = $('[data-role="slider-services"]');
  //
  // const move = (e) => {
  //   TweenMax.to($plusCursor, 0, {
  //     x: e.clientX - 32,
  //     y: e.clientY - 32,
  //   });
  // };
  //
  // $container.on('mousemove', (e) => {
  //   move(e);
  // });

  const plusCursor = document.querySelector('[data-plus-cursor]');
  const containers = document.querySelectorAll('[data-role="slider-services"]');

  const sliderObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        plusCursor.classList.add('cursor-active');
        const move = (e) => {
          TweenMax.to(plusCursor, 0, {
            x: e.clientX - 32,
            y: e.clientY - 32,
          });
        };
        containers.forEach((container)=> {
          container.addEventListener('mousemove', (e) => {
            move(e);
          });
        });
      } else {
        plusCursor.classList.remove('cursor-active');
      }
    });
  }, { rootMargin: '-400px 0px -300px 0px' });

  containers.forEach(el => sliderObserver.observe(el));
}
