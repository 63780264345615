// node-modules
import { TweenLite } from 'gsap/TweenLite';

// constants
import { SELECTORS } from '@/scripts/constants';
import { FORM_SELECTORS, FORM_ELEMENTS } from './constants';

// components
import { Captcha } from './components/Captcha';
import { InputBlock } from './components/InputBlock';

// assets
import './Form.scss';

export class Form {
  constructor(element) {
    this.element = element;

    const { sitekey = null, autosubmit } = this.element.dataset;

    this.autosubmit = autosubmit;

    this.message = this.element.querySelector(FORM_ELEMENTS.message_block);
    this.inputBlocks = Array.from(this.element.querySelectorAll(FORM_ELEMENTS.input_block))
      .map(inputBlock => new InputBlock(inputBlock, this));

    if (sitekey) {
      this.captcha = new Captcha({
        element: this.element.querySelector(FORM_ELEMENTS.captcha_block),
        sitekey,
        formInstance: this,
      });
    }

    this.init();
  }

  get isValid() {
    return this.element.checkValidity();
  }

  scrollToInvalid() {
    const target = this.element.querySelector(`.${FORM_SELECTORS.invalid}`);
    const distance = $(target).offset().top;
    TweenLite.to(window, 0.8, { scrollTo: { y: distance - 200, autoKill: false } });
  }

  validate() {
    if (this.isValid) {
      if (this.captcha) {
        this.captcha.execute();
      } else {
        this.validationSuccess();
      }
    }
    // else {
    //   this.validationFail();
    // }
  }

  validationSuccess() {
    this.element.submit();
  }

  // validationFail() {
  //
  // }

  setupEvents() {
    this.element.addEventListener('submit', (e) => {
      e.preventDefault();
      this.validate();
    });
    if (this.autosubmit) {
      this.inputBlocks.forEach((inputBlock) => {
        inputBlock.on(['change'], () => this.element.submit());
      });
    }
  }

  init() {
    this.setupEvents();
    if (this.message) {
      setTimeout(() => {
        this.message.classList.add(SELECTORS.hidden);
      }, 3000);
    }
  }
}
