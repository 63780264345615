/* eslint-disable */
import { Loader } from 'google-maps';
import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import { TweenMax } from 'gsap/TweenMax';
import MAP_STYLES from './styles.json';
import './js-marker-clusterer';
import detectIE from '@/scripts/utils/detect-ie';
import getDistance from '@/scripts/utils/get-distance';
import './googleMaps.scss';

const defaults = {
  showroomContainer: document.querySelector('[data-type="showrooms"]'),
  showroomSelector: '[data-type="showroom"]',
  showroomMapSelector: '[data-type="google-map"]',
  showroomGrid: null,
  showroomDataLat: 'data-coords-lat',
  showroomDataLng: 'data-coords-lng',
  showroomDataDistance: 'data-distance',
  loaderSelector: '[data-type="loader"]',
  locationContainerSelector: '[data-location="location-search"]',
  locationCountrySelector: '[data-location="location-country"]',
  locationCitySelector: '[data-location="location-city"]',
  errorSelector: '.input-block__error',
  inputBlockSelector: '.input-block',
  inputInvalidClass: 'input-block_invalid',
};
export class Showrooms {
  constructor(props) {
    const options = Object.assign({}, defaults, props);
    this.showroomContainer = options.showroomContainer;
    this.showroomSelector = options.showroomSelector;
    this.showrooms = this.showroomContainer.querySelectorAll(this.showroomSelector);
    this.showroomMap = document.querySelector(options.showroomMapSelector);
    this.loader = this.showroomContainer.querySelector(options.loaderSelector);

    this.locationSearchContainer = this.showroomContainer.querySelector(options.locationContainerSelector);
    this.locationCountry = this.showroomContainer.querySelector(options.locationCountrySelector);
    this.locationCity = this.showroomContainer.querySelector(options.locationCitySelector);
    this.scrollable = this.showroomContainer.querySelector('.contact__scrollable');
    this.errorSelector = options.errorSelector;
    this.inputBlockSelector = options.inputBlockSelector;
    this.inputInvalidClass = options.inputInvalidClass;

    this.mapKEY = document.querySelector('[data-type="google-map"]').dataset.key;
    this.showroomGrid = options.showroomGrid;
    // this.mapKEY = options.mapKEY;
    this.showroomDataLat = options.showroomDataLat;
    this.showroomDataLng = options.showroomDataLng;
    this.showroomDataDistance = options.showroomDataDistance;
    this.map = null;
    this.showroomsCoords = [];
    this.geoOptions = {
      maximumAge: 5 * 60 * 1000,
      timeout: Infinity,
      // enableHighAccuracy: true,
    };
    this.containerOffsetTop = 0;
    this.prevMarker = null;
    this.markerCluster = null;
    this.markers = [];
    this.activeCoordsLat = 0;
    this.init();
  }

  clearSelectShowroom() {
    const activeShowroom = this.showroomContainer.querySelector('.active');
    if (activeShowroom) {
      activeShowroom.classList.remove('active');
    }
  }

  static getTopCoords(elem) {
    const box = elem.getBoundingClientRect();
    return box.top + pageYOffset;
  }

  toggleActiveShowroom() {
    this.clearSelectShowroom();
    const currentShowroom = this.showroomContainer.querySelector(`[${this.showroomDataLat}="${this.activeCoordsLat}"]`);
    if (currentShowroom) {
      currentShowroom.classList.add('active');
      const {height} = currentShowroom.getBoundingClientRect();
      const top = currentShowroom.offsetTop;
      const scroll = top;
      console.log({scroll})

      TweenMax.to(this.scrollable, 1, { scrollTo: {
        y: scroll
      }});
    }
  }

  static setMapStyle(map) {
    const styledMapType = new google.maps.StyledMapType(MAP_STYLES);
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  }

  initMap() {
    const mapLoader = new Loader(this.mapKEY, { version: 'weekly', libraries: ['places'] });

    mapLoader.load().then((google) => {
      const that = this;
      let icoOptions = {};
      let mcOptions = {};
      let latLng;
      let marker;
      const coordinates = this.showroomsCoords.map(coords => [coords.lat, coords.lng]);
      let lat = 0;
      let lng = 0;
      let sumLat = 0;
      let sumLng = 0;

      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
      }

      Array.from(coordinates).forEach((prop) => {
        sumLat += Number(prop[0]);
        sumLng += Number(prop[1]);
      });

      if (coordinates.length) {
        lat = sumLat / coordinates.length;
        lng = sumLng / coordinates.length;
      }

      this.map = new google.maps.Map(this.showroomMap, {
        zoom: 5,
        center: new google.maps.LatLng(lat, lng),
        disableDefaultUI: false,
      });

      this.markerSrc = this.showroomMap.dataset.markerSrc;

      this.markers = this.showroomsCoords.map((coords) => {
        latLng = new google.maps.LatLng(coords.lat, coords.lng);

        marker = new google.maps.Marker({
          position: latLng,
          map: this.map,
          color: '#ffffff',
          origin: detectIE() ? null : new google.maps.Point(35, 35),
          icon: detectIE() ? null : this.markerSrc,
        });

        google.maps.event.addListener(marker, 'click', (mark => () => {
          const markLat = mark.position.lat();
          const markLng = mark.position.lng();
          this.activeCoordsLat = markLat;
          that.toggleActiveShowroom();
          if (this.prevMarker) {
            this.prevMarker.setIcon(this.markerSrc);
          }
          this.prevMarker = mark;
          mark.setIcon(this.markerSrc);
          this.fitMapToMarker(markLat, markLng);

        })(marker));

        return marker;
      });

      Showrooms.setMapStyle(this.map);

      icoOptions = [
        {
          url: this.markerSrc,
          width: 1,
          height: 1,
          textColor: '#ffffff',
        }, {
          url: this.markerSrc,
          width: 1,
          height: 1,
          textColor: '#ffffff',
        }, {
          url: this.markerSrc,
          width: 1,
          height: 1,
          textColor: '#ffffff',
        }, {
          url: this.markerSrc,
          width: 1,
          height: 1,
          textColor: '#ffffff',
        }, {
          url: this.markerSrc,
          width: 1,
          height: 1,
          textColor: '#ffffff',
        }];
      mcOptions = {
        gridSize: 20,
        styles: icoOptions,
        maxZoom: 18,
      };
      this.markerCluster = new MarkerClusterer(this.map, this.markers, mcOptions);

      const input = this.showroomContainer.querySelector('[data-search-location]');
      const autocomplete = new google.maps.places.Autocomplete(input);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo('bounds', this.map);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        const { viewport, location } = place.geometry;
        if (location) {
          const lat = location.lat();
          const lng = location.lng();
          Array.from(this.showrooms).forEach((el) => {
            const x = parseFloat(el.dataset.coordsLat);
            const y = parseFloat(el.dataset.coordsLng);
            el.dataset.distance = getDistance({ lat, lng }, { lat: x, lng: y }).toFixed(2);
          });
          this.grid.updateSortData(this.showrooms);
          this.grid.arrange({ sortBy: 'distance' });
          const searchError = this.locationSearchContainer.querySelector('.search-error');
          if (searchError) {
            this.locationSearchContainer.removeChild(searchError);
          }
        }
        if (viewport) {
          this.map.fitBounds(viewport);
        } else {
          this.fitMapToMarker(lat, lng);
        }
      });
    });
  }

  locationSearchValidation(countryValue, cityValue) {
    let check = true;
    const countryInputBlock = this.locationCountry.closest(this.inputBlockSelector);
    const countryError = countryInputBlock.querySelector(this.errorSelector);
    const cityInputBlock = this.locationCity.closest(this.inputBlockSelector);
    const cityError = cityInputBlock.querySelector(this.errorSelector);

    if (!countryValue || countryValue === 'select country') {
      countryInputBlock.classList.add(this.inputInvalidClass);
      countryError.innerText = 'Field can not be empty.';
      check = false;
    } else {
      countryInputBlock.classList.remove(this.inputInvalidClass);
      countryError.innerText = '';
    }
    if (!cityValue || cityValue === '') {
      cityInputBlock.classList.add(this.inputInvalidClass);
      cityError.innerText = 'Field can not be empty.';
      check = false;
    } else {
      cityInputBlock.classList.remove(this.inputInvalidClass);
      cityError.innerText = '';
    }

    return check;
  }

  init() {
    this.containerOffsetTop = Showrooms.getTopCoords(this.showroomContainer);
    this.initMap();

    Array.from(this.showrooms).forEach((el) => {
      const coords = {
        lat: 0,
        lng: 0,
      };
      coords.lat = parseFloat(el.dataset.coordsLat);
      coords.lng = parseFloat(el.dataset.coordsLng);
      this.showroomsCoords.push(coords);

      el.addEventListener('click', () => {
        const lat = parseFloat(el.dataset.coordsLat);
        const lng = parseFloat(el.dataset.coordsLng);
        const dataID = parseInt(el.dataset.id, 10);
        this.fitMapToMarker(lat, lng);
        this.activeCoordsLat = lat;
        google.maps.event.trigger(this.markers[dataID], 'click');
      });
    });

    this.grid = new Isotope(this.showroomGrid, {
      itemSelector: this.showroomSelector,
      percentPosition: true,
      getSortData: {
        distance: `[${this.showroomDataDistance}] parseFloat`,
      },
    });
  }

  fitMapToMarker(lat, lng) {
    this.map.setCenter(new google.maps.LatLng(lat, lng));
    this.map.setZoom(12);
  }
}
